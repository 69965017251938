/**
 * Author:    HeckDanNorth
 *            DD/MM/YYYY
 * Created:   23.09.2023
 * 
 * Class: Projects.tsx
 * 
 * Library: React / React-Page
 * Description: Defines a basic page of projects.
 * 
 **/

import React, { useState } from "react";

import Navbar from "../pages/Navbar";
import "../style.css";
import Profile from "./Profile";

import ds from "./img/docker_services.png";
import vm from "./img/vmware_mainpage.png";
import das from "./img/dashy.png";
import dm from "./img/fit_dm.jpg";
import lm from "./img/fit_lm.jpg";
import topo from "./img/Home Network-Logical topology.drawio.png";



import Gallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';



<meta name="viewport" content="width=device-width, initial-scale=1.0" />

const images = [
  {
    original: das,
    thumbnail: das,
    description: "Custom Homelab Dashboard",
  },
  {
    original: topo,
    thumbnail: topo,
    description: "Home Network Topology",
  },
  {
    original: dm,
    thumbnail: dm,
    description: "Self Hosted Mining Rigs",  
  },
  {
    original: lm,
    thumbnail: lm,
    description: "Self Hosted Mining Rigs",
  },
  {
    original: vm,
    thumbnail: vm,
    description: "VmWare ESXI 6.0 host",
  },
  {
    original: ds,
    thumbnail: ds,
    description: "Docker Compose services running Locally",
  },
];




function Projects() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    console.log("dark toggled");
    setIsDarkMode(!isDarkMode);
  };
  return (
    <div className={`resume ${isDarkMode ? "dark-mode" : ""}`}>
      <Navbar />
      <header>
        <Profile />
      </header>
      <section className="projects">
        <h2 className="pRoot">root</h2>
        <h2 className="pColon">:</h2>
        <h2 className="pSiggle">~</h2>
        <h2 className="pRest">$ man College Projects</h2>
        <div className="collegeProjects">
          <h3> VOiCE (Capstone Project at Sheridan College) </h3>
          <h5>Role: Project Lead</h5>
          <p className="description">
            As the Project Lead for the Sheridan College capstone project, 
            I spearheaded a team of four members in our program. Our mission was to design a web application 
            for a real company provided by Sheridan. In this pivotal role, I ensured project deadlines were met, 
            maintained order within our team, and actively contributed to the codebase. Conducting weekly meetings, 
            we utilized various enterprise resources to streamline our workflow and successfully achieve our project goals. </p>
        </div>
        <div className="collegeProjects">
          <h3>Game Inventory Manager (Sheridan College)</h3>
          <h5>Role: Programming Lead</h5>
          <p className="description">In the capacity of Programming Lead for Game Inventory Manager (GIM) at Sheridan College, my teammate and I undertook a final exam project to showcase our database knowledge. We developed a simple yet effective Java application that manipulated the Firebase database, providing real-time display of results. This project demonstrated our proficiency in database management and Java programming. </p>
        </div>
        <div className="collegeProjects">
          <h3>Pandemic Tracker (Sheridan College)</h3>
          <h5>Roles: Programming Lead & Project Lead</h5>
          <p className="description">
            As the Technical Support and System Administrator,
             I orchestrated the creation of a straightforward program to 
             receive and display real-time data from the ongoing pandemic. 
             This program sourced its data from a live website, processed and sorted it,
              and then presented the information to the user. This project showcased my 
              ability to manage both the technical and project management aspects 
              of a development initiative.</p>
        </div>
      </section>
      <section className="volunteer">
        <h2 className="pRoot">root</h2>
        <h2 className="pColon">:</h2>
        <h2 className="pSiggle">~</h2>
        <h2 className="pRest">$ grep "Freelance_Projects.jsx"</h2>

        <div className="freelanceProjects">
          <h3>Orbt Home Decor (Freelance Project)</h3>
            <p>
              Revamped a second-hand furniture company's website, 
              introducing a new shopping cart system and seamlessly 
              integrating Square payment processing. 
              Developed a custom CSS theme to enhance user experience,
              implemented account registration, login, and product filters, 
              and introduced real-time AJAX search. The overhaul significantly 
              improved user convenience and engagement, leading to an unprecedented 
              uptick in sales and heightened social media presence after the redesign.
            </p>
        </div>

        <div className="freelanceProjects">
         <h3>Sage Liquidation (Freelance Project)</h3>        
          <p>
            As the Technical Support and System Administrator, 
            I initiated the redesign of a second-hand furniture company's website using WordPress.
            Developed a custom CSS theme from scratch, 
            integrating Square and WooCommerce for streamlined payment processing
            and enhanced e-commerce capabilities. Managed a vast product catalog,
            overseeing the sale of over 5,000 products, resulting in a substantial increase in successful sales.
            The redesign contributed to a remarkable revenue boost of over $30,000.
          </p>
        </div>

        <div className="freelanceProjects">
          <h3>Rustic Attic (Freelance Project)</h3>
            <p>
              As the Technical Support and System Administrator, 
              made substantial contributions to a Handcrafted 
              Furniture company by designing a visually striking website. 
              Implemented specialty software to assist customers in ordering 
              doors efficiently, leading to increased sales. Conceptualized 
              and developed a bespoke quote generator tailored to their specific 
              business needs. Assumed the role of website designer and maintainer, 
              ensuring a continuous online presence until eventual shutdown. 
              Demonstrated creative design skills, effective problem-solving, 
              and commitment to sustaining an online identity.
            </p>
        </div>
        <div className="freelanceProjects">
          <h3>Handmade portfolio resume</h3>
          <p className="degree">This site was designed by coded by me!</p>
        </div>

        <div className="freelanceProjects">
          <h3>Handmade portfolio resume</h3>
          <p className="degree"> <a href='https://github.com/HackdaNorth/docker-compose-yaml/blob/main/docker-compose-public.yml'> My Docker compose yaml file </a> </p>
        </div>        

        

        <div className="freelanceProjects">
          <div className="image-gallery-container">
              <h3>Gallery</h3>
                <Gallery items={images} />
              </div>      

          </div>
      </section>
      <h4>Please take a look at my <a href='https://github.com/HackdaNorth'> Github </a> or Look at my code <a href='/Showcase'> Showcase </a> for more Projects</h4>
      <footer>
      <button className="toggle-mode" onClick={toggleDarkMode}>
        {isDarkMode ? "Light Mode" : "Dark Mode"}
      </button>
      </footer> 
    </div>

    
  );
}

export default Projects;
