/**
 * Author:    HeckDanNorth
 * Created:   11.03.2023
 * 
 * Class: Profile.jsx
 * 
 * 
 **/

import React from "react";
import profilePicture from './img/tile_500.jpg'
import "../style.css";

function Profile() {

  return (
    <div >
      <header>
        <div className="profile">
          <img src={profilePicture} alt="Profile" />
          <h1 className="pRoot">root</h1> <h1 className="pColon">:</h1>
          <h1 className="pSiggle">~</h1>
          <h1 className="pRest">$ cd ~/.Connor Murray</h1>
          <p className="contact-info">
            <i className="fa fa-phone"></i> 1 (365)-308-1186 <br />
            <i className="fa fa-envelope"></i> connorforhelp@gmail.com
          </p>
        </div>
      </header>
      <footer>
      </footer>
    </div>
  );
}

export default Profile;
