/**
 * Author:    HeckDanNorth
 * Created:   09.03.2023
 * 
 * Class: Landing.tsx
 * 
 * Library: React / React-Photo-Gallery 
 * Description: Defines a landing page with some basic content, for users visting the webpage.
 * 
 **/

import React, { useState } from "react";
// import { Link, animateScroll as scroll } from "react-scroll";
import Navbar from "../pages/Navbar";
import Profile from "../pages/Profile"
import "../style.css";

function Landing() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    console.log("dark toggled");
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`resume ${isDarkMode ? "dark-mode" : ""}`}>
      <Navbar />
      <header>
      <Profile />
      </header>
      <button className="toggle-mode" onClick={toggleDarkMode}>
        {isDarkMode ? "Light Mode" : "Dark Mode"}
      </button>

        <section className="projects">
          <h2 className="pRoot">root</h2>
          <h2 className="pColon">:</h2>
          <h2 className="pSiggle">~</h2>
          <h2 className="pRest">$ man Professional Summary:</h2>
            <div className="collegeProjects">
              <p>Results-driven System Administrator with 9 years of extensive experience in system maintenance, 
                installing & patching, user administration, technical support, cybersecurity, and software & system troubleshooting. 
                Adept at ensuring the seamless operation of critical IT infrastructure, demonstrating expertise in high availability, 
                critical thinking, and team building.</p>
            </div>
          </section>

        <section className="projects">
          <h2 className="pRoot">root</h2>
          <h2 className="pColon">:</h2>
          <h2 className="pSiggle">~</h2>
          <h2 className="pRest">$ ls -la Key Skills:</h2>
            <div className="collegeProjects">
              <ul>
                <li>System Maintenance</li>
                <li>Installing & Patching</li>
                <li>User Administration</li>
                <li>Technical Support</li>
                <li>Cybersecurity</li>
                <li>Software & System Troubleshooting</li>
              </ul>
            </div>
          </section>

        <section className="projects">
          <h2 className="pRoot">root</h2>
          <h2 className="pColon">:</h2>
          <h2 className="pSiggle">~</h2>
          <h2 className="pRest">$ systemctl show Soft Skills:</h2>
            <div className="collegeProjects">
              <ul>
                <li>High Availability</li>
                <li>Critical Thinking</li>
                <li>Team Building</li>
                <li>Adaptability</li>
                <li>Enthusiasm</li>
                <li>Analysis Skills</li>
              </ul>
            </div>
          </section>

          <section className="projects">
            <h2 className="pRoot">root</h2>
            <h2 className="pColon">:</h2>
            <h2 className="pSiggle">~</h2>
            <h2 className="pRest">$ top Career Achievements:</h2>
              <div className="collegeProjects">
                <p>Successfully set up and maintained Docker containers, enhancing system efficiency and scalability.
                Established and managed domains, DNS, and network infrastructure, ensuring optimal functionality.
                Resolved end-user issues promptly, maintaining a high level of customer satisfaction.
                Contributed to the creation of web applications, showcasing versatility in development.</p>
              </div>
          </section>

          <section className="projects">
            <h2 className="pRoot">root</h2>
            <h2 className="pColon">:</h2>
            <h2 className="pSiggle">~</h2>
            <h2 className="pRest">$ head -n 100 Career Achievements:</h2>
              <div className="collegeProjects">
                <p>Successfully set up and maintained Docker containers, enhancing system efficiency and scalability.
                Established and managed domains, DNS, and network infrastructure, ensuring optimal functionality.
                Resolved end-user issues promptly, maintaining a high level of customer satisfaction.
                Contributed to the creation of web applications, showcasing versatility in development.</p>
              </div>
          </section>  

          <section className="projects">
            <h2 className="pRoot">root</h2>
            <h2 className="pColon">:</h2>
            <h2 className="pSiggle">~</h2>
            <h2 className="pRest">$ tail Education:</h2>
              <div className="collegeProjects">
                <p>Bachelor's Degree from Sheridan College, Ontario, Canada.</p>
              </div>
          </section>  

          <section className="projects">
            <h2 className="pRoot">root</h2>
            <h2 className="pColon">:</h2>
            <h2 className="pSiggle">~</h2>
            <h2 className="pRest">$ curl Career goals:</h2>
              <div className="collegeProjects">
                <p>Seeking a challenging career in System Administration and Technical Support, 
                  leveraging extensive experience in maintaining technological machines and providing exceptional IT solutions.</p>
              </div>
          </section>  

          <section className="projects">
            <h2 className="pRoot">root</h2>
            <h2 className="pColon">:</h2>
            <h2 className="pSiggle">~</h2>
            <h2 className="pRest">$ dig Points to Emphasize:</h2>
              <div className="collegeProjects">
                <p>Unique strengths lie in the comprehensive understanding and adept management of various technological machines, ensuring optimal performance and reliability across diverse systems.</p>
              </div>
          </section>  
    </div>
  );
}

export default Landing;
