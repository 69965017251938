/**
 * Author:    HeckDanNorth
 * Created:   09.03.2023
 * 
 * Class: Navbar.tsx
 * 
 * Library: React / React-Photo-Gallery 
 * Description: Defines a basic Navbar //Further Comments Required
 * 
 **/
import React from "react";
//import Dropdown from './Dropdown';
//import { BrowserRouter, Route, Link } from "react-router-dom";
import "../style.css";

function Navbar() {
  const navItems = [
    { text: 'Home', link: '/' },
    { text: 'Projects', link: '/Projects' },
    { text: 'Experience', link: '/Experience' },
    { text: 'Education', link: '/Education' },
    { text: 'About Me', link: '/About' },
    { text: 'Download CV', link: 'resume.pdf' },
    { text: 'My Github', link: 'https://github.com/HackdaNorth' },
    { text: 'Code Showcase', link: '/Showcase' },
  ];
  // const dropdownItems = [
  //   { text: 'Service 1', link: '#service1' },
  //   { text: 'Service 2', link: '#service2' },
  //   { text: 'Service 3', link: '#service3' },
  // ];
  
  return (
    <nav className="nb" >
        {navItems.map((item, index) => (
          <React.Fragment key={index}>
           <a className="nb" href={item.link}>{item.text}</a>
           {/* {item.text === 'Projects' && <Dropdown items={dropdownItems} />} */}
         </React.Fragment>
        ))}
        
    </nav>
    // <nav>
    //   <ul className="nb">
    //     <li className="nb">
    //       <Link to="/">Home</Link>
    //     </li>
    //     <li className="nb">
    //       <Link to="/Education">Education</Link>
    //     </li>
    //     <li className="nb">
    //       <Link to="/Experience">Experience</Link>
    //     </li>
    //     <li className="nb">
    //       <Link to="/About">About</Link>
    //     </li> 
    //     <li className="nb">
    //       <a href="resume.pdf" download>Download CV</a>
    //     </li>
    //     <li className="nb">
    //       <a href="https://github.com/HackdaNorth">My Github</a> 
    //     </li>
    //   </ul>
    // </nav>
  );
}

export default Navbar;
