/**
 * Author:    HeckDanNorth
 * Created:   09.03.2023
 * 
 * Class: About.tsx
 * 
 * Library: React / React-Photo-Gallery 
 * Description: Defines a basic About  page. //Further Comments Required
 * 
 **/
import React, { useState } from "react";
import Navbar from "../pages/Navbar";
import "../style.css";
import Profile from "./Profile";
import carPicture from './img/fit_1280.jpg'

function About() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    console.log("dark toggled");
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`resume ${isDarkMode ? "dark-mode" : ""}`}>
      <Navbar />
      <header>
        <Profile />
      </header>
      <section className="objective">
        <h2 className="pRoot">root</h2>
        <h2 className="pColon">:</h2>
        <h2 className="pSiggle">~</h2>
        <h2 className="pRest">$ ls Objective</h2>
        <p>
        Seeking a challenging technology position in a dynamic organization where my technical expertise, problem-solving skills, 
        and commitment to exceptional service contribute to organizational success.
        </p>
        </section>
        <section className="summary">
        <h2 className="pRoot">root</h2>
        <h2 className="pColon">:</h2>
        <h2 className="pSiggle">~</h2>
        <h2 className="pRest">$ tail Summary.txt</h2>
        <p class="indent">
            Experienced and highly motivated Systems Administrator with over five years of experience in managing and maintaining complex IT systems. 
        Skilled in identifying and resolving technical issues, maintaining system uptime, and implementing new solutions to improve system performance. 
        Adept at working with a range of software and systems, including Windows, Linux, and Unix operating systems, and virtualization technologies. 
        Strong communication and collaboration skills, with a passion for delivering exceptional customer service and building strong relationships with stakeholders. 
        A self-starter who takes pride in completing tasks to the highest standards and is committed to achieving organizational goals.
        I've not only have over 10 years of experience working with computers with a indepth knowledge of aarange of different systems, from hardware to digital.
        </p>
      </section>
      <section className="about">
        <h2 className="pRoot">root</h2>
        <h2 className="pColon">:</h2>
        <h2 className="pSiggle">~</h2>
        <h2 className="pRest">$ df /About/Interests</h2>
        <p>
          I'm a tech enthusiast and pet parent to two playful Huskies, Leo and Zeus, who bring boundless joy to my life.
          My passion for technology is wide-ranging, encompassing computer systems, servers, Raspberry Pis, and more. 
          I'm always exploring the world of self-hosted services, including setting up Plex, NAS, VMWare ESXi, Docker, and diving into cybersecurity.
          When I'm not immersed in the tech realm, you can find me under the hood of cars, getting my hands dirty with some DIY auto work. I'm also an avid gamer, enjoying a diverse range of video games.
          My love for music knows no bounds, spanning from analog to digital. I enjoy experimenting with various audio setups to enhance my listening experience.
          I spend most of my time coding, constantly expanding my tech knowledge, or simply unwinding with some gaming sessions. My interests are as diverse as my two Husky companions, Leo and Zeus."
        </p>
        </section>
        <h1>TARZAN THE CAR</h1>
        <img 
          style={{height:'535px'}} 
          src={carPicture}
          alt="BMW 328i 2007"
          />
      <footer>
        <button className="toggle-mode" onClick={toggleDarkMode}>
          {isDarkMode ? "Light Mode" : "Dark Mode"}
        </button>
      </footer>
    </div>
  );
}

export default About;
