/**
 * Author:    HeckDanNorth
 * Created:   11.03.2023
 * 
 * Class: Profile.jsx
 * 
 * Library: React / React-Photo-Gallery 
 * Description: Defines a profile component that I can include anywhere.
 * 
 **/

import React, { useState } from "react";
import Navbar from "../pages/Navbar";
import "../style.css";
import Profile from "./Profile";


import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const CodeSnippet = ({ language, code }) => {
  return (
    <SyntaxHighlighter language={language} style={atomDark}>
      {code}
    </SyntaxHighlighter>
  );
};

function Showcase() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    console.log("dark toggled");
    setIsDarkMode(!isDarkMode);
  };
  return (

    <div className={`resume ${isDarkMode ? "dark-mode" : ""}`}>
    <Navbar />
    <header>
      <Profile />
    </header>
        <div className="profile">
        <h3>Custom SSH script, Uses ssh-agent built in for automatic key authentication</h3>
<CodeSnippet language="powershell" code={`Write-Host "
1 pihome 
2 piVirtual
3 NASStorage
4 MediaManager
5 PlexServer
6 Valheim
8 Iridium
9 Prilvaged Iridium
11 CM4 Host
12 VPN"
$Choice = Read-Host "Pick Option Now..."

Write-Host "Opening Server Now.."
function pi-Virtual { 
Start-Process cmd -Argument  "/k ssh user@hostIP -p sshPort"  }
function piVirtual { 
Start-Process cmd -Argument  "/k ssh user@hostIP -p sshPort"  }
function MediaManager { 
Start-Process cmd -Argument  "/k ssh user@hostIP -p sshPort" }
function PlexServer { 
Start-Process cmd -Argument  "/k ssh user@hostIP -p sshPort" }
function Valheim { 
Start-Process cmd -Argument  "/k ssh user@hostIP -p sshPort" }
function NASStorage { 
Start-Process cmd -Argument  "/k ssh user@hostIP -p sshPort" }
function Iridium { 
Start-Process cmd -Argument  "/k ssh user@hostIP -p sshPort" }
function RootedIridium { 
Start-Process wt.exe -ArgumentList  "ssh user@hostIP -p sshPort" }
function cm4 { 
Start-Process wt.exe -ArgumentList  "ssh user@hostIP -p sshPort" }
function vpn { 
Start-Process wt.exe -ArgumentList  "ssh user@hostIP -p sshPort" }

switch ( $Choice )
{
1 { piHome }
2 { piVirtual }
3 { NASStorage }
4 { MediaManager }
5 { PlexServer }
6 { Valheim }
8 { Iridium }
9 { RootedIridium }
11 { cm4 }
12 { vpn }

}
`} />
        <h3>Custom script to open two SSH connections, run two commands, then run a app locally on machine.</h3>
<CodeSnippet language="python" code={`
:: Replace these variables with your server details
set server_ip=hostIP
set server_user=hostname
set server_port=hostport

:: Commands to run on the remote server
set command1="/remote_machine_path/run/bin/mangosd -c /remote_machine_path/run/etc/mangosd.conf -a /remote_machine_path/run/etc/ahbot.conf"
set command2="/remote_machine_path/run/bin/realmd -c /remote_machine_path/run/etc/realmd.conf"

:: Open two Command Prompt windows for SSH connections
start "SSH Connection 1" cmd /k "ssh -p %server_port% %server_user%@%server_ip% %command1%"
start "SSH Connection 2" cmd /k "ssh -p %server_port% %server_user%@%server_ip% %command2%"

:: Run local programs on the local machine
start "First Executable" "C:/local_app_path/app.exe"
timeout /t 1 /nobreak > nul
start "Second Executable" "C:/Second_local_app_path/app2.exe" --version=development

`} />
        <h3>Simple TSV file to CSV file script.</h3>
<CodeSnippet language="python" code={`#to tsv from csv
import pandas as pd
df = pd.read_csv(r'udproducts2.csv')
df.to_csv('data.tsv', sep='\t', encoding='utf-8', index=False)`}/>
        <h3>Custom Python script modifies CSV database to implement a new column </h3>
<CodeSnippet language="python" code={`import csv

# Create a dictionary to store the URLs and corresponding data
url_data = {}

# Read data from 'data.csv' and store URLs as keys and data as values
with open('data.csv', 'r', encoding='utf-8') as data_file:
    data_reader = csv.reader(data_file)
    for row in data_reader:
        if row:
            url, data = row
            url_data[url] = data

# Update 'updated_products.csv' by adding a new column 'newImages'
new_file = 'updated_products_with_newImages.csv'

with open('updated_products.csv', 'r', encoding='utf-8') as product_file, \
     open(new_file, 'w', newline='', encoding='utf-8') as updated_product_file:
    product_reader = csv.reader(product_file)
    product_writer = csv.writer(updated_product_file)
    
    header = next(product_reader)
    header.append('newImages')  # Add a new column to the header
    product_writer.writerow(header)  # Write the updated header to the new file

    # Iterate through each product data entry
    for row in product_reader:
        product_url = row[20]  # Assuming the product URL is in the 21st column
        data = url_data.get(product_url, '')  # Get data from 'data.csv' based on the URL
        row.append(data)  # Add data to the 'newImages' column
        product_writer.writerow(row)  # Write the updated row to the new file

print(f'Updated product data with newImages saved to {new_file}')
`}/>
        <h3>Custom python script, rips content within a specific element from supplied html files.</h3>
<CodeSnippet language="python" code={`from bs4 import BeautifulSoup
import csv
import re

# Replace the following with the paths to your HTML files
file_paths = [
    'CENSORED.html',
    'CENSORED_2.html',
    'CENSORED_3.html',
    'CENSORED_4.html',
    'CENSORED_5.html'
]

# Create an empty list to store all the extracted data
all_data = []

# Define the replacement text for image URLs
replacement_text = 'replacement_URL_of_images'

# Loop through each file in the list
for file_path in file_paths:
    with open(file_path, 'r', encoding='utf-8') as file:
        html = file.read()

    # Replace file paths with the desired URL
    html = re.sub(r'/./CENSORED(_ / d+)?_files/', replacement_text + '/', html)

    # Create a BeautifulSoup object to parse the modified HTML
    soup = BeautifulSoup(html, 'html.parser')

    # Find all <a> elements with the specified attribute 'data-v-3c974045'
    link_elements = soup.find_all('a', {'data-v-3c974045': True})

    # Find all <img> elements inside those <a> elements
    for a in link_elements:
        img = a.find('img')
        if img:
            # Extract the 'href' attribute from the <a> element (product URL)
            product_url = a['href']

            # Extract the 'src' attribute from the <img> element (image URL)
            image_url = img['src']

            # Append the product URL and image URL as a tuple
            all_data.append((product_url, image_url))

# Open a CSV file for writing
with open('data.csv', 'w', newline='') as csvfile:
    # Create a CSV writer
    csv_writer = csv.writer(csvfile)

    # Write the header row
    csv_writer.writerow(['Product URL', 'Image URL'])

    # Write all the data to the CSV file
    for data in all_data:
        csv_writer.writerow(data)

print(f'Saved {len(all_data)} records to data.csv')
`}/>
 <h3>Python Generate hashed password</h3>
<CodeSnippet language="python" code={`import hashlib
import os

def generate_salt():
    return os.urandom(16).hex()

def hash_password(password, salt):
    password_with_salt = password + salt
    hashed_password = hashlib.sha256(password_with_salt.encode()).hexdigest()
    return hashed_password

# Get user input for password
user_password = input("Enter your new password: ")

# Generate a new salt
salt = generate_salt()

# Hash the password with the salt
hashed_password = hash_password(user_password, salt)

print("Salt:", salt)
print("Hashed Password:", hashed_password)
`}/>
 <h3>Custom Python script, reads mmedaddress from supplied file and outputs to another for processing.</h3>
<CodeSnippet language="python" code={`import re

def extract_values(file_path, attribute_name):
    with open(file_path, 'r') as file:
        content = file.read()

    pattern = rf'{attribute_name}="(.*?)"'
    values = re.findall(pattern, content)
    return values

def write_values_to_file(values, output_file):
    with open(output_file, 'w') as file:
        for value in values:
            file.write(f"{value}\n")

def main():
    file_path = "BMW.xdf"  # Update with the actual file path
    output_file = "output_values.txt"

    attribute_name = "mmedaddress"
    values = extract_values(file_path, attribute_name)
    write_values_to_file(values, output_file)

    print(f"Values extracted from '{attribute_name}' attributes and saved to '{output_file}'.")

if __name__ == "__main__":
    main()
`}/>
 <h3>Snippet of a Java Http Servlet </h3>
<CodeSnippet language="java" code={`package sheridan;

import java.io.IOException;
import java.util.List;

import javax.servlet.RequestDispatcher;
import javax.servlet.ServletException;
import javax.servlet.annotation.WebServlet;
import javax.servlet.http.HttpServlet;
import javax.servlet.http.HttpServletRequest;
import javax.servlet.http.HttpServletResponse;


@WebServlet(
        name = "selectliquourservlet",
        urlPatterns = "/SelectLiquor"
)

public class SelectLiquourServlet extends HttpServlet {

	/**
	 * 
	 */
	private static final long serialVersionUID = -1831978487671842618L;
	
    @Override
    protected void doPost(HttpServletRequest req, HttpServletResponse resp) throws ServletException, IOException {

        String liquorType = req.getParameter("Type");

        LiquourService liquorService = new LiquourService();
        LiquourType l = LiquourType.valueOf(liquorType);

        List<String> liquorBrands = liquorService.getAvailableBrands(l);

        req.setAttribute("brands", liquorBrands);
        RequestDispatcher view = req.getRequestDispatcher("result.jsp");
        view.forward(req, resp);

    }	

}
`} />
 <h3>Custom Python script to match similar bytes within a supplied file, and output paired matches.</h3>
<CodeSnippet language="python" code={`def read_pairs_from_file(file_path):
    with open(file_path, 'r') as file:
        lines = file.readlines()
    pair_list = [line.strip().split() for line in lines]
    return pair_list

def find_similar_pairs(pair_list1, pair_list2):
    successful_matches = []

    unique_segments = set()
    for line2_pairs in pair_list2:
        for j in range(len(line2_pairs) - 3):
            segment = tuple(line2_pairs[j:j+4][::-1])
            if all(byte != "00" for byte in segment) and \
               all(byte != "FF" for byte in segment) and \
               all(byte != "40" for byte in segment) and \
               all(byte != "80" for byte in segment):
                unique_segments.add(segment)

    for line1_idx, line1_pairs in enumerate(pair_list1):
        print(f"Processing line {line1_idx + 1}/{len(pair_list1)} from pairs1.txt")

        for i in range(len(line1_pairs) - 3):
            segment = tuple(line1_pairs[i:i+4])
            if all(byte != "00" for byte in segment) and \
               all(byte != "FF" for byte in segment) and \
               all(byte != "40" for byte in segment) and \
               all(byte != "80" for byte in segment):
                if segment in unique_segments:
                    matched_bytes = " ".join(segment[::-1]) + " <-> " + " ".join(segment)
                    hex_offset1 = "{:08x}".format(line1_idx * 16 + i)  # Calculate HEX offset for pairs1

                    # Find the corresponding Pairs2 Line and HEX Offset
                    for line2_idx, line2_pairs in enumerate(pair_list2):
                        for j in range(len(line2_pairs) - 3):
                            if segment == tuple(line2_pairs[j:j+4][::-1]):
                                hex_offset2 = "{:08x}".format(line2_idx * 8 + j)  # Calculate HEX offset for pairs2
                                successful_matches.append((line1_idx + 1, hex_offset1, line2_idx + 1, hex_offset2, segment, matched_bytes))
                                print(f"Match found: Pairs1 Line {line1_idx + 1}, HEX Offset {hex_offset1} , Pairs2 Line {line2_idx + 1}, HEX Offset {hex_offset2}, Matched Bytes: Pairs1: {segment[::-1]} <->  Pairs2: {segment}")
                                break

    return successful_matches

def save_matches_to_file(matches, output_file):
    with open(output_file, 'w') as file:
        for match in matches:
            file.write(f"Match Found:\n")
            file.write(f"Pairs1 Line {match[0]}, HEX Offset {match[1]}:\n")
            file.write(f"Pairs2 Line {match[2]}, HEX Offset {match[3]}:\n")
            file.write(f"Matched Bytes: Pairs1: {match[5]} <->  Pairs2: {match[4]}\n\n")

def main():
    pairs1_path = "pairs1.txt"
    pairs2_path = "pairs2.txt"
    output_file = "matches_with_hex_offset_v8_v4.txt"

    pair_list1 = read_pairs_from_file(pairs1_path)
    pair_list2 = read_pairs_from_file(pairs2_path)

    successful_matches = find_similar_pairs(pair_list1, pair_list2)
    save_matches_to_file(successful_matches, output_file)

    print("Matches saved to", output_file)

if __name__ == "__main__":
    main()

`} />
 <h3>AWS Yaml Snippet of creating a EC2 Instance with a security group.</h3>
<CodeSnippet language="yaml" code={`# Create an EC2 Instance with a security group
Resources:
  MyInstance:
    Type: AWS::EC2::Instance
    Properties:
      AvailabilityZone: us-east-1a
      ImageId: ami-02e136e904f3da870
      InstanceType: t2.micro
      KeyName: Key-demo-01
      SecurityGroups:
        - !Ref CloudFormedSG
      Tags: 
        - Key: Name
          Value: cloudformed
                  
  # Security group named CloudFormedSG
  CloudFormedSG:
    Type: AWS::EC2::SecurityGroup
    Properties:
      GroupDescription: Allow SSH and HTTP
      SecurityGroupIngress: 
      - IpProtocol: tcp
        FromPort: 80
        ToPort: 80
        CidrIp: 0.0.0.0/0
      - IpProtocol: tcp
        FromPort: 22
        ToPort: 22
        CidrIp: 0.0.0.0/0
`} />
 <h3>Java snippet of a AccountDispatcher from Project VOiCE at Sheridan College</h3>
<CodeSnippet language="java" code={`// @author Connor  github.com/HackdaNorth


package com.VOICE.VOICESOFTWARE.Controllers;

import java.util.ArrayList;
import java.util.Collection;
import java.util.List;
import org.springframework.security.crypto.bcrypt.BCryptPasswordEncoder;
import org.springframework.stereotype.Component;
import com.VOICE.VOICESOFTWARE.DomainObjects.Account;
import com.VOICE.VOICESOFTWARE.UseCaseManagers.AccountPersistenceManager;
import com.VOICE.VOICESOFTWARE.Repositories.AccountRepository;
import com.VOICE.VOICESOFTWARE.Repositories.RoleRepository;

@Component
public class AccountPersistenceDispatcher implements AccountPersistenceManager   {
	
	// ******************************
	// Private Variables
	//

	// ******************************
	private List<Account> accounts = new ArrayList<Account>();
		
	// ******************************
	// Public Methods
	// @saveAccountToRepository
	// @loginToAccount
	// @searchFilters
	// @getAllAccounts
	// ******************************
	public AccountPersistenceDispatcher () {
	}

	@Override
	public void saveAccountToRepository(AccountRepository accRepo, RoleRepository roleRepo, Account account) {
		account.getRoles().add(roleRepo.findByRolename("ROLE_USER"));
		accRepo.save(account);
	}
	@Override
	public boolean deleteAccountFromRepo(AccountRepository accRepo, int accountID) {
		accRepo.deleteById(accountID);
		return true;
	}
	//loginToAccount
	@Override
	public Account loginToAccount(AccountRepository accRepo, String accEmail, String accPassword) {
		//New Password Encoder
	  	BCryptPasswordEncoder pwdEncode = new BCryptPasswordEncoder();
	  	//Get current Password and encode it into new variable
	  	boolean isPasswordMatched = false;
		//Created a new blank Account
		Account acc = new Account(); 
		List<Account> currentAcc = new ArrayList<Account>();
		currentAcc.addAll((Collection<? extends Account>) accRepo.findAll());
		//for each account in the list of accounts
		for(Account account : currentAcc) {
			//match email
		    if(account.getAccEmail().equals(accEmail)) {
		    	isPasswordMatched = pwdEncode.matches(accPassword, account.getAccPassword());
		    	//match password
		    	if(isPasswordMatched == true) {
		    		//attempt was made.
		    		acc = account;
		    	} else {
		    		//incorrect, return false.
		    		acc = null;
		    	}// end of else
			} // end of if
		} // end of for
		return acc;
	}// end of loginToAccount

	@Override
	public List<Account> searchFiltered(AccountRepository accRepo, String accProvince, String accCity, String schoolBoard, int numOfChildren, int childAge, String typeOfHearing) {
		//here we create the blank list
		List<Account> searchResult = new ArrayList<Account>();
		//All accounts found in the database by accRepo is added into searchResult
		searchResult.addAll(accRepo.findByAccProvinceAndAccCityAndSchoolBoardAndAndNumOfChildrenAndChildAgeAndTypeOfHearing(accProvince, accCity, schoolBoard, numOfChildren, childAge, typeOfHearing));
	
		//return the filtered list
		return searchResult;
	} // end of searchFiltered
	
		//find by ID
	@Override
	public List<Account> getAllAccounts(AccountRepository accRepo) {
		//Create a new blank searchResult list 
		List<Account> searchResult = new ArrayList<Account>();
		//add every single record in database for viewing.
		searchResult.addAll((Collection<? extends Account>) accRepo.findAll());
		//return entire list
		return searchResult;
	} // end of getAllAccounts
	
	@Override
	public boolean emailExists(String accEmail) {
		for(Account account : accounts) {
			if(account.getAccEmail().equals(accEmail)) {
				return true;
			}
		}
		return false;
	}
}
`} />
 <h3>Java snippet of a Domain Object Account from Project VOiCE at Sheridan College</h3>
<CodeSnippet language="java" code={`// @author Connor  github.com/HackdaNorth


package com.VOICE.VOICESOFTWARE.DTO;

import java.util.ArrayList;
import java.util.List;
import java.util.Map;

import com.VOICE.VOICESOFTWARE.DomainObjects.Account;


public class AccountRequestResponse  {

	// ******************************
	// Public Variables for AccountRequestResponse
	//
	// ******************************
	private boolean success = true; 
	private List<Account> searchResult = new ArrayList<Account>();
	private List<Account> viewedAccount = new ArrayList<Account>();
	private Account loggedInAcc;

	private String returnMessageToUser;
	private String htmlPage;
	private Map<String, String> map;

	// ******************************
	// Public Methods For Account 
	//
	// @AccountRequestResponse(boolean condition, String message, String url)
	// @AccountRequestResponse(boolean condition, List<Account> searchlist, String url)
	// @AccountRequestResponse(Account viewedAccount, String url) 
	// @isSuccess 
	// @setSuccess
	// @getHtmlPage
	// @setHtmlPage
	// @getReturnMessageToUser
	// @setReturnMessageToUser
	// @getList
	// @getViewedProfile
	// ******************************
	
	public AccountRequestResponse( boolean condition, String message, String url) {
	
		this.success = condition;
		this.returnMessageToUser = message;
		this.htmlPage = url;
	}

	public AccountRequestResponse(boolean condition, List<Account> searchlist, String url) {
		this.success = condition;
		this.searchResult = searchlist;
		this.htmlPage = url;
	}
	public AccountRequestResponse(Account viewedAccount,  Map<String, String> map, String url) {
		this.map = map;
		this.viewedAccount.add(viewedAccount);
		this.htmlPage = url;
	}
	public AccountRequestResponse(Account loggedInAcc, boolean condition, String message, String url) {
		this.loggedInAcc = loggedInAcc;
		this.success = condition;
		this.returnMessageToUser = message;
		this.htmlPage = url;
	}
	
	public Map<String, String> getMap() {
		return map;
	}
	public boolean isSuccess() {
		return success;
	}
	public void setSuccess(boolean success) {
		this.success = success;
	}

	public String getHtmlPage() {
		return htmlPage;
	}
	public void setHtmlPage(String htmlPage) {
		this.htmlPage = htmlPage;
	}

	public String getReturnMessageToUser() {
		return returnMessageToUser;
	}
	public void setReturnMessageToUser(String returnMessageToUser) {
		this.returnMessageToUser = returnMessageToUser;
	}
	public List<Account> getList() {

		return searchResult;
	}
	public List<Account> getViewedProfile() {
		
		return viewedAccount;
	}
	public Account getAccount() {
		return loggedInAcc;
	}

}`} />
Java snippet of a the main class from Project VOiCE at Sheridan College
<CodeSnippet language="java" code={`package com.VOICE.VOICESOFTWARE;

//Spring boot imports
import org.springframework.boot.SpringApplication;
import org.springframework.boot.autoconfigure.SpringBootApplication;
//Controller Imports

import com.VOICE.VOICESOFTWARE.Controllers.AccountPersistenceDispatcher;
import com.VOICE.VOICESOFTWARE.Controllers.MessagePersistanceDispatcher;

//UseCaseManagers Imports
import com.VOICE.VOICESOFTWARE.UseCaseManagers.AccountManager;
import com.VOICE.VOICESOFTWARE.UseCaseManagers.AccountPersistenceManager;
import com.VOICE.VOICESOFTWARE.UseCaseManagers.MessageManager;
import com.VOICE.VOICESOFTWARE.UseCaseManagers.MessagePersistanceManager;

import lombok.AllArgsConstructor;


@SpringBootApplication
@AllArgsConstructor
public class VoiceSoftware {

	//Account variables
	private static AccountManager accountManager;
	private static AccountPersistenceManager accountpersistenceManager;
	
	//Message variables
	private static MessageManager messageManager;
	private static MessagePersistanceManager messagePersistenceManager;

	//main
	public static void main(String[] args) {
		// run voice software application
		SpringApplication.run(VoiceSoftware.class, args);
	}
	
	//AccountManager
	public static AccountManager instanceOfAccountManager () {
		//if the account manager doesn't exist make a new one 
		if(accountManager == null) {
			accountManager = new AccountManager();
		}
		// return the new accountManager to VOICEDispatcher
		return accountManager;
	}

`} />

</div>
    <footer>
    <button className="toggle-mode" onClick={toggleDarkMode}>
      {isDarkMode ? "Light Mode" : "Dark Mode"}
    </button>
    </footer>
  </div>
  );
}


export default Showcase;
