/**
 * Author:    HeckDanNorth
 * Created:   09.03.2023
 *
 * Class: Experience.tsx
 *
 * Library: React / React-Photo-Gallery
 * Description: Defines a basic Experience page.
 *
 **/
import React, { useState } from "react";
import Navbar from "../pages/Navbar";
import "../style.css";
import Profile from "./Profile";
//import "../909.css";
//import PhotoAlbum from "react-photo-album";

// const images = [
//   {
//     id: 1,
//     src: "skills.png",
//     alt: "Image 1",
//   },
// ];
function Experience() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    console.log("dark toggled");
    setIsDarkMode(!isDarkMode);
  };
  return (
    <div className={`resume ${isDarkMode ? "dark-mode" : ""}`}>
      <Navbar />
      <header>
        <Profile />
      </header>
      <section className="experience">
        <h2 className="pRoot">root</h2>
        <h2 className="pColon">:</h2>
        <h2 className="pSiggle">~</h2>
        <h2 className="pRest">$ sudo Experience</h2>
        <div className="job">
          <h3>System Administrator, Sage Liquidation</h3>
          <p className="job-date">Feburary 15, 2019 - Present</p>
          <ul>
              <li>
                <span>Proficiently developed and consistently updated the business website, ensuring it remains current and relevant.</span>
              </li>
              <li>
                <span>Managed day-to-day operations to maintain the smooth functioning of critical services.</span>
              </li>
              <li>
                <span>Provided essential support for end-user hardware, addressing and resolving technical issues.</span>
              </li>
              <li>
                <span>Developed and implemented innovative strategies to optimize system efficiency.</span>
              </li>
              <li>
                <span>Offered comprehensive network-wide support across both Ethernet and wireless domains.</span>
              </li>
              <li>
                <span>Acted as an on-call support resource, addressing a wide range of end-user technical difficulties promptly and effectively.</span>
              </li>                            
            </ul>
        </div>
        <div className="job">
          <h3>Website Administrator, The Rustic Attic</h3>
          <p className="job-date">September 15, 2016 - January 01, 2019</p>
          <ul>
              <li>
                <span>Spearheaded and managed the company's online internet presence by maintaining the website, network, and more.</span>
              </li>
              <li>
                <span>Succesfully manage critical Linux infrastructure services troubleshoot Linux issues to root cause, focused on reliability.</span>
              </li>
              <li>
                <span>Configure, manage and maintain security devices and provide mitigation stratgies, and recommendations on industry standard security practices.</span>
              </li>
              <li>
                <span>Assisted in monitor and managing critical Linux infrastructure services.</span>
              </li>
              <li>
                <span>Proficiently installed and managed Linux software, optimizing company network. </span>
              </li> 
              <li>
                <span>Adeptly Reviewed and analyzed complex IT systems identifying risk and security exposures.</span>
              </li>                           
            </ul>
        </div>
        <div className="job">
          <h3>Cross trained/Harvey’s Associate, Harvey's</h3>
          <p className="job-date">May 13, 2015 - March 21, 2018</p>
          <ul>
            <li>Maintain a website media presence, and website backend.</li>
            <li>
              Create engaging campaigns & Identify new company products, and log
              inventory.
            </li>
            <li>
              Handle day-to-day implementation of digital tactics to business
              sites
            </li>
          </ul>
        </div>
      </section>
      <section className="skills">
        <h2 className="pRoot">root</h2>
        <h2 className="pColon">:</h2>
        <h2 className="pSiggle">~</h2>
        <h2 className="pRest">$ cat Skills.txt</h2>
      </section>
      <h3 className="justify-content" >Skills</h3>
      <section className="displayskills">
          <div className="skillsbg" >
            <span>Backend Development</span>
            <ul>
              <li>
                <span>NodeJS</span>
              </li>
              <li>
                <span>Springboot</span>
              </li>
              <li>
                <span>Nginx</span>
              </li>
              <li>
                <span>Apache</span>
              </li>
              <li>
                <span>Jenkins</span>
              </li>
              <li>
                <span>Postman</span>
              </li>
              <li>
                <span>Docker</span>
              </li>
              <li>
                <span>Docker Compose</span>
              </li>
              <li>
                <span>VMWare</span>
              </li>
              <li>
                <span>Virtualization</span>
              </li>
              <li>
                <span>Java</span>
              </li>
              <li>
                <span>Python</span>
              </li>
              <li>
                <span>OpenShift</span>
              </li>            
              <li>
                <span>AWS Cloud</span>
              </li>
              <li>
                <span>API</span>
              </li>                            
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Hardware Management</span>
            <ul>
              <li>
                <span>Linux</span>
              </li>
              <li>
                <span>Windows</span>
              </li>
              <li>
                <span>MacOS</span>
              </li>
              <li>
                <span>Ubuntu</span>
              </li>
              <li>
                <span>Dell</span>
              </li>
              <li>
                <span>HP</span>
              </li>
              <li>
                <span>Raspbian</span>
              </li>
              <li>
                <span>REHL</span>
              </li>
              <li>
                <span>CentOS</span>
              </li>
              <li>
                <span>OpenSUSE</span>
              </li>
              <li>
                <span>Debian</span>
              </li>                                          
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Frontend Web Development</span>
            <ul>
              <li>
                <span>Ionic</span>
              </li>
              <li>
                <span>HTML</span>
              </li>
              <li>
                <span>PHP</span>
              </li>
              <li>
                <span>React</span>
              </li>
              <li>
                <span>Thymeleaf</span>
              </li>
              <li>
                <span>Spring</span>
              </li>              
              <li>
                <span>Angular</span>
              </li>
              <li>
                <span>Bootstrap</span>
              </li>   
              <li>
                <span>CSS</span>
              </li>            
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Content Managment Systems</span>
            <ul>
              <li>
                <span>Laravel</span>
              </li>
              <li>
                <span>Wordpress</span>
              </li>
              <li>
                <span>WooCommerce</span>
              </li>
              <li>
                <span>Cloudflare</span>
              </li>
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Security Solutions</span>
            <ul>
              <li>
                <span>Cryptography</span>
              </li>
              <li>
                <span>Blockchain</span>
              </li>
              <li>
                <span>SSO</span>
              </li>
              <li>
                <span>Encryption</span>
              </li>
              <li>
                <span>Authorization</span>
              </li>
              <li>
                <span>Audit</span>
              </li>
              <li>
                <span>Checkpoint</span>
              </li>
              <li>
                <span>FireEye</span>
              </li>
              <li>
                <span>Juniper</span>
              </li> 
              <li>
                <span>Encryption</span>
              </li>
              <li>
                <span>Intrusion Detection</span>
              </li>
              <li>
                <span>Prevention Systems (IPS)</span>
              </li>                           
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Information Security</span>
            <ul>
              <li>
                <span>Risk Reports</span>
              </li>
              <li>
                <span>Impact Analysis</span>
              </li>
              <li>
                <span>Action Plans</span>
              </li>
              <li>
                <span>Risk Assessment</span>
              </li>
              <li>
                <span>Gap Redemdiation</span>
              </li>
              <li>
                <span>Security Awareness</span>
              </li>
              <li>
                <span>Mitgation Strategies</span>
              </li>              
            </ul>
          </div>         
          <div className="skillsbg" >
            <span>Database Development</span>
            <ul>
              <li>
                <span>MySQL</span>
              </li>
              <li>
                <span>MonogoDB</span>
              </li>
              <li>
                <span>phpMyAdmin</span>
              </li>
              <li>
                <span>MariaDB</span>
              </li>
              <li>
                <span>Postgres</span>
              </li>
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Network Management</span>
            <ul>
              <li>
                <span>Switches</span>
              </li>
              <li>
                <span>Pfsense</span>
              </li>
              <li>
                <span>Proxy</span>
              </li>
              <li>
                <span>Wireguard</span>
              </li>
              <li>
                <span>Cloud Computing</span>
              </li>
              <li>
                <span>Active Directory</span>
              </li>
              <li>
                <span>DHCP</span>
              </li>
              <li>
                <span>Cisco</span>
              </li>  
              <li>
                <span>Load balancing</span>
              </li>
              <li>
                <span>Routers</span>
              </li>  
              <li>
                <span>Wireless LAN</span>
              </li>
              <li>
                <span>Firewalls</span>
              </li>   
              <li>
                <span>VLANS</span>
              </li>
              <li>
                <span>VPN</span>
              </li>                                                                 
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Aglie Methodologies</span>
            <ul>
              <li>
                <span>Scrum</span>
              </li>
              <li>
                <span>Kanbam</span>
              </li>
              <li>
                <span>Lean</span>
              </li>
              <li>
                <span>Feature Driven Development (FDD)</span>
              </li>
              <li>
                <span>Adaptive Software Development (ASD)</span>
              </li>              
            </ul>
          </div>          
          <div className="skillsbg" >
            <span>Adobe Creative Cloud</span>
            <ul>
              <li>
                <span>Adobe Photoshop</span>
              </li>
              <li>
                <span>Adobe Illustrator</span>
              </li>
              <li>
                <span>Adobe After Effects</span>
              </li>
              <li>
                <span>Adobe Premiere Pro</span>
              </li>
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Microsoft Office</span>
            <ul>
              <li>
                <span>Microsoft Word</span>
              </li>
              <li>
                <span>Microsoft Powerpoint</span>
              </li>
              <li>
                <span>Microsoft Excel</span>
              </li>
              <li>
                <span>Microsoft Teams</span>
              </li>
            </ul>
          </div>
      </section>
      <h3 className="justify-content" >Soft Skills</h3>
      <section className="displayskills">
      <div className="skillsbg" >
            <span>Flexibility</span>

            <ul>
              <li>
                <span>Capable</span>
              </li>
              <li>
                <span>Versatile</span>
              </li>
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Creativity</span>
            <ul>
              <li>
                <span>Critical Thinking</span>
              </li>
              <li>
                <span>Visual Thinking</span>
              </li>
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Problem Solving</span>

            <ul>
              <li>
                <span>Communication</span>
              </li>
              <li>
                <span>Dedcision-making</span>
              </li>
              <li>
                <span>Team-building</span>
              </li>
            </ul>
          </div>
          <div className="skillsbg" >
            <span>Organizaional skills</span>
            <ul>
              <li>
                <span>Ethusiasm</span>
              </li>
              <li>
                <span>Work Ethic</span>
              </li>
              <li>
                <span>Time managment</span>
              </li>
              <li>
                <span>Self motivation</span>
              </li>
            </ul>
          </div>
      </section>
      <footer>
        <button className="toggle-mode" onClick={toggleDarkMode}>
          {isDarkMode ? "Light Mode" : "Dark Mode"}
        </button>
      </footer>
    </div>
  );
}

export default Experience;
